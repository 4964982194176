import React from "react";

const ProgressBar = ({ progress, total }) => {
  const progressPercentage = Math.round(
    ((progress < 0 ? 0 : progress) / total) * 100
  );

  return (
    <div className="progress mb-3">
      <div
        className="progress-bar"
        role="progressbar"
        style={{ width: `${progressPercentage}%` }}
        aria-valuenow={progressPercentage}
        aria-valuemin="0"
        aria-valuemax="100"
      >
        {progressPercentage}%
      </div>
    </div>
  );
};

export default React.memo(ProgressBar);
