import React from "react";
import { FormattedMessage } from "react-intl";

import ErrorLabel from "./common/errorLabel";

//Componente domanda dicotomica (Sì/No)

const DichotomousQuestion = ({ question, locale, onQuestionResponse }) => {
  const { questionNo, responseValue } = question;
  const radioName = `dichotomous_${questionNo}`;

  const handleChange = ({ currentTarget }) => {
    onQuestionResponse(questionNo, currentTarget.value);
  };

  return (
    <div className="d-flex">
      <div className="flex-fill">
        {question.text[locale]}
        <ErrorLabel question={question} />
      </div>
      <div className="min-vw-8 text-right align-middle">
        <div className="form-check form-check-inline">
          <input
            className="form-check-input"
            type="radio"
            name={radioName}
            id={`${radioName}_yes`}
            value="1"
            checked={responseValue === "1"}
            onChange={handleChange}
          />
          <label className="form-check-label" htmlFor={`${radioName}_yes`}>
            <FormattedMessage id="dichotomousValue.yes" />
          </label>
        </div>
        <div className="form-check form-check-inline ml-1">
          <input
            className="form-check-input"
            type="radio"
            name={radioName}
            id={`${radioName}_no`}
            value="2"
            checked={responseValue === "2"}
            onChange={handleChange}
          />
          <label className="form-check-label" htmlFor={`${radioName}_no`}>
            <FormattedMessage id="dichotomousValue.no" />
          </label>
        </div>
      </div>
    </div>
  );
};

export default DichotomousQuestion;
