import React from "react";
import { FormattedMessage } from "react-intl";

/* Componente per selezioen locale (lingua)
 * Al momento il numero di locali previse è fissato a 3 (Italiano, Inglese e Tedesco) */

const LocaleSelector = ({ locale, onLocaleChange }) => {
  const handleChange = ({ currentTarget }) => {
    onLocaleChange(currentTarget.value);
  };

  return (
    <div className="form-inline">
      <label htmlFor="localeSelector" className="h6 font-weight-light mr-3">
        <FormattedMessage id="localeSelect.text" />
      </label>
      <select
        className="form-control"
        style={{ display: "inline" }}
        id="localeSelector"
        value={locale}
        onChange={handleChange}
      >
        <option value="it">Italiano</option>
        <option value="en">English</option>
        <option value="de">Deutsch</option>
      </select>
    </div>
  );
};

export default LocaleSelector;
