import React from "react";
import tglogo from "../resources/img/logo-tecnicagasket.png";

//import { Link, NavLink } from "react-router-dom";

const NavBar = () => {
  return (
    <nav className="navbar navbar-light bg-light">
      <div className="container">
        <span className="navbar-brand">
          <a
            href="https://www.tecnicagasket.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={tglogo} alt="Tecnica Gasket S.p.A." />
          </a>
        </span>
        <span className="navbar-text h2 pr-2">
          <em>Customer Satisfaction</em>
        </span>
      </div>
    </nav>
  );
};

export default React.memo(NavBar);
