import React from "react";
import { FormattedMessage } from "react-intl";

import ErrorPage from "./errorPage";
import LikertQuestion from "../questions/likertQuestion";
import LikertHeader from "../questions/likertHeader";
import NumberQuestion from "../questions/numberQuestion";
import DichotomousQuestion from "../questions/dichotomousQuestion";
import TextQuestion from "../questions/textQuestion";
import ListQuestion from "../questions/listQuestion";

const QuestionPage = ({
  questionnaire,
  pageNo,
  locale,
  onPageChange,
  onQuestionResponse
}) => {
  const page = questionnaire[pageNo];
  if (!page) return <ErrorPage messageId="error.noPageConfig" />;

  const { groupNo, questions, hasLikertHeader } = page;

  //Imposta il titolo della pagina, prendendo il testo corretto a seconda della locale
  const title = page.title[locale];

  //Restituisce true se la pagina è l'ultima del questionario
  const isLastPage = () => {
    return pageNo === questionnaire.length - 1;
  };

  const handleSubmit = e => {
    e.preventDefault();
    onPageChange(1, isLastPage());
  };

  const handlePreviousPage = () => {
    onPageChange(-1, false);
  };

  /* Handling inserimento risposta sulla pagina.
   * Poichè l'aggiornamento di una risposta sul questionario richiede sia l'indicazione del gruppo
   * che del numero della domanda, questo metodo non fa altro che rilevare l'evento e rilanciarlo
   * sul componente Questionnaire aggiungendo il numero del gruppo. In pratica serve per evitare di
   * passare il numero del gruppo ad ogni singolo componente-domanda. */
  const handlePageQuestionResponse = (questionNo, responseValue) => {
    onQuestionResponse(groupNo, questionNo, responseValue);
  };

  /* Rileva automaticamente la configurazione delle domande dal file questionnaire.json (in base
   * al numero di pagina passato) e compone il layout della pagina. */
  return (
    <React.Fragment>
      <h3>{title}</h3>
      <form className="mt-3" onSubmit={handleSubmit}>
        <table className="table table-striped">
          {hasLikertHeader ? <LikertHeader /> : null}
          <tbody>
            {questions.map(q => {
              let questionComponent;

              //Aggiunge il tipo di domanda, in base alla configurazione
              switch (q.type) {
                case "likert":
                  questionComponent = (
                    <LikertQuestion
                      question={q}
                      locale={locale}
                      onQuestionResponse={handlePageQuestionResponse}
                    />
                  );
                  break;

                case "number":
                  questionComponent = (
                    <NumberQuestion
                      question={q}
                      locale={locale}
                      onQuestionResponse={handlePageQuestionResponse}
                    />
                  );
                  break;

                case "dichotomous":
                  questionComponent = (
                    <DichotomousQuestion
                      question={q}
                      locale={locale}
                      onQuestionResponse={handlePageQuestionResponse}
                    />
                  );
                  break;

                case "list":
                  questionComponent = (
                    <ListQuestion
                      question={q}
                      locale={locale}
                      onQuestionResponse={handlePageQuestionResponse}
                    />
                  );
                  break;

                default:
                  //Default domanda testo
                  questionComponent = (
                    <TextQuestion
                      question={q}
                      locale={locale}
                      onQuestionResponse={handlePageQuestionResponse}
                    />
                  );
                  break;
              }
              return (
                <tr
                  key={q.questionNo}
                  className={q.error ? "table-danger" : null}
                >
                  <td className="align-middle">{questionComponent}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <div className="d-flex justify-content-end">
          {pageNo > 0 ? (
            <button
              type="button"
              className="btn btn-outline-primary mt-3 mr-2"
              onClick={handlePreviousPage}
            >
              <FormattedMessage id={"questionnairePage.previous"} />
            </button>
          ) : (
            ""
          )}
          <button
            type="submit"
            className="btn btn-primary mt-3"
            autoFocus={true}
          >
            <FormattedMessage
              id={
                isLastPage()
                  ? "questionnairePage.finish"
                  : "questionnairePage.next"
              }
            />
          </button>
        </div>
      </form>
    </React.Fragment>
  );
};

export default QuestionPage;
