import React from "react";
import { FormattedMessage } from "react-intl";

const FinalLandingPage = () => {
  return (
    <>
      <h2>
        <FormattedMessage id="landingPage.dearCustomer" />
      </h2>
      <p className="lead">
        <FormattedMessage
          id={"landingPage.filledSurvey"}
          values={{
            br: <br />,
            a: (...chunks) => (
              <a href="https://www.tecnicagasket.com">{chunks}</a>
            ),
          }}
        />
      </p>
    </>
  );
};

export default React.memo(FinalLandingPage);
