import React from "react";
import { FormattedMessage } from "react-intl";

//Riga di intestazione per domande di tipo Likert (normalmente messa all'inizio di una serie/gruppo di domande)

const LikertHeader = () => {
  return (
    <thead>
      <tr>
        <th className="align-middle w-100">
          <div className="d-flex">
            <div className="flex-fill"></div>
            <div className="min-vw-6 text-center likert-header">
              <FormattedMessage id="likertValue.great" />
            </div>
            <div className="min-vw-6 text-center likert-header">
              <FormattedMessage id="likertValue.good" />
            </div>
            <div className="min-vw-6 text-center likert-header">
              <FormattedMessage id="likertValue.sufficient" />
            </div>
            <div className="min-vw-6 text-center likert-header">
              <FormattedMessage id="likertValue.insufficient" />
            </div>
            <div className="min-vw-6 text-center likert-header">
              <FormattedMessage id="likertValue.poor" />
            </div>
          </div>
        </th>
      </tr>
    </thead>
  );
};

export default React.memo(LikertHeader);
