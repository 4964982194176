import React from "react";

import ErrorLabel from "./common/errorLabel";

//Componente domanda di tipo numero

const NumberQuestion = ({ question, locale, onQuestionResponse }) => {
  const { questionNo, responseValue } = question;

  const handleChange = ({ currentTarget }) => {
    const { value } = currentTarget;
    onQuestionResponse(questionNo, value);
  };

  const uom = question.uom && question.uom[locale]; //Rileva l'unità di misura, se esiste

  return (
    <div className="d-flex">
      <div className="flex-fill">
        {question.text[locale]}
        <ErrorLabel question={question} />
      </div>
      <div className="min-vw-6 text-right align-middle">
        <input
          type="text"
          placeholder={uom}
          className="form-control text-right min-vw-6 d-inline"
          id={`number_${questionNo}`}
          value={responseValue}
          onChange={handleChange}
          maxLength="6"
        />
      </div>
    </div>
  );
};

export default NumberQuestion;
