import React from "react";

import ErrorLabel from "./common/errorLabel";

//Componente domanda di likert (scala di valori discreti)

const LikertQuestion = ({ question, locale, onQuestionResponse }) => {
  const { questionNo, responseValue } = question;
  const radioName = `likert_${questionNo}`;

  const handleChange = ({ currentTarget }) => {
    onQuestionResponse(questionNo, currentTarget.value);
  };

  return (
    <div className="d-flex align-items-center">
      <div className="flex-fill">
        {question.text[locale]}
        <ErrorLabel question={question} />
      </div>
      {[5, 4, 3, 2, 1].map((opt) => (
        <div
          key={opt}
          className="min-vw-6 text-center"
          /* Il click sul <div> corrispondente setta il valore, per una migliore UX.
               L'utente non deve per forza cliccare sul pallino del radio-button per impostare il
               valore. */
          onClick={() => onQuestionResponse(questionNo, `${opt}`)}
        >
          <input
            className="form-check d-inline"
            type="radio"
            name={radioName}
            id={`${radioName}_${opt}`}
            value={opt}
            checked={responseValue === `${opt}`}
            onChange={handleChange}
          />
        </div>
      ))}
    </div>
  );
};

export default LikertQuestion;
