import React from "react";

//Pagina di attesa con Spinner

const SpinnerPage = () => {
  return (
    <div className="d-flex mt-7 justify-content-center">
      <div className="spinner-border" style={{ color: "#0075be" }}></div>
    </div>
  );
};

export default React.memo(SpinnerPage);
