import _ from "lodash";

//Definizione costanti
export const NUMBER_QUESTION_MAX_VALUE = 200;

//Funzioni di utility per la gestione dei questionari.

/* Completa il questionario indicato, ossia cicla su ogni domanda definita e vi aggiunge un campo responseValue e un campo error.
 * Il questionario completato viene restituito come risultato ed è un clone del questionario passato;
 * Questo serve sostanzialmente per evitare errori di React nella gestione dei campi input (controlled/uncontrolled input). */
export function completeQuestionnaire(questionnaire) {
  //Clona il questionario (deep, con losash)
  const clonedQuestionnaire = _.cloneDeep(questionnaire);

  //Questo però non clona le singole pagine e le domande, che sono oggetti nidificati, per cui bisogna farlo in maniera specifica
  for (let page of clonedQuestionnaire) {
    for (let question of page.questions) {
      if (!question.hasOwnProperty("responseValue"))
        question.responseValue = "";
      if (!question.hasOwnProperty("error")) question.error = "";
    }
  }

  return clonedQuestionnaire;
}

//Esegue la validazione di una singola risposta a una domanda di tipo "number"
function validateNumberResponse(question) {
  const { responseValue } = question;

  if (!responseValue.match("^[0-9]+$")) {
    //Il valore deve rappresentare un numero
    question.error = "validationError.notAnInteger";
    return;
  }

  const intValue = parseInt(responseValue);
  //Il valore dev'essere compreso tra 1 e 200 (predefinito)
  //0 è già controllato dalla validazione generale perchè è un valore Falsy
  if (intValue < 1 || intValue > NUMBER_QUESTION_MAX_VALUE)
    question.error = "validationError.intInvalidValue";

  /* La reimpostazione del valore è un facile trucco per rimuovere eventuali zeri iniziali
   * e formattare bene il numero. */
  question.responseValue = `${intValue}`;
}

/* Valida la pagina del questionario passata, impostando l'attributo "error" di ogni domanda opportunamente,
 * se la relativa risposta non ha passato la validazione. Il valore dell'attributo "error" è il codice i18n
 * del messaggio di errore corrispondente.
 * Attenzione che la funzione modifica direttamente l'oggetto pagina passato. */
export function validateQuestionnairePage(page) {
  let anyError = false;

  for (let question of page.questions) {
    question.error = ""; //Reset eventuali validazioni precedenti
    //Per tutti i tipi di domanda, se la risposta è obbligatoria, controlla che ci sia un valore
    if (question.mandatory && !question.responseValue) {
      question.error = "validationError.noResponse";
      anyError = true;
    } else {
      //Validazioni specifiche per tipo di domanda
      switch (question.type) {
        case "number":
          validateNumberResponse(question);
          break;
        default:
      }
      if (question.error) anyError = true;
    }
  }

  return anyError; //Flag presenza di errori in almeno una risposta del questionario.
}
