import React from "react";
import { Route, Switch } from "react-router-dom";

import NavBar from "./components/navBar";
import Questionnaire from "./components/questionnaire";
import NotFound from "./components/notFound";

function App() {
  return (
    <>
      <NavBar />
      <main className="container mt-5">
        <Switch>
          <Route path="/questionnaire/:qid" component={Questionnaire} />
          <Route path="/" component={NotFound} />
        </Switch>
        <p className="w-100 text-center font-weight-light mt-5">
          <small>
            <a
              href="https://www.tecnicagasket.com/"
              target="_blank"
              rel="noopener noreferrer"
              className="tg-color"
            >
              Tecnica Gasket S.p.A.
            </a>{" "}
            | Via Foppe, 15 - 25030 Paratico (BS) | Tel. +39 035924418 |{" "}
            <a href="mailto:mktg@tecnicagasket.com" className="tg-color">
              mktg@tecnicagasket.com
            </a>
          </small>
        </p>
      </main>
    </>
  );
}

export default App;
