import React from "react";
import { FormattedMessage } from "react-intl";
import { NUMBER_QUESTION_MAX_VALUE } from "../../../utils/questionnaireUtils";

//Etichetta per la visualizzazione dei messaggi di errore relativi alle domande

const ErrorLabel = ({ question }) => {
  const { type: questionType, questionNo, error } = question;

  return error ? ( //Ritorna il contenuto solo se effettivamente c'è un errore
    <small
      id={`${questionType}_${questionNo}_error`}
      className="form-text text-danger"
    >
      <em>
        <FormattedMessage
          id={error}
          values={{
            maxValue: NUMBER_QUESTION_MAX_VALUE
          }}
        />
      </em>
    </small>
  ) : null;
};

export default React.memo(ErrorLabel);
