import React from "react";
import { FormattedMessage } from "react-intl";

//Pagina di errore

const ErrorPage = ({ messageId }) => {
  return (
    <>
      <h1 className="text-danger">
        <FormattedMessage id="error" />
      </h1>
      <p className="lead text-danger">
        <FormattedMessage id={messageId} />
      </p>
    </>
  );
};

export default React.memo(ErrorPage);
