import React from "react";

//Pagina "not found"

const NotFound = () => {
  return (
    <>
      <h1>Not found</h1>
      <p className="lead">The specified resource has not been found.</p>
    </>
  );
};

export default React.memo(NotFound);
