import React, { useState, useEffect } from "react";
import { FormattedMessage } from "react-intl";

import ErrorLabel from "./common/errorLabel";

//Componente domanda di tipo testo. Al momento è l'unico tipo di domanda implementato in un componente stateful.

const TextQuestion = ({ question, locale, onQuestionResponse }) => {
  //La lunghezza massima consentita è fissa di 2000 caratteri (definita nel Web Service su Domino)
  const MAX_TEXT_LENGTH = 2000;

  const [charsLeft, setCharsLeft] = useState(MAX_TEXT_LENGTH); //Stato

  const { questionNo, responseValue, error } = question;

  const handleChange = ({ target }) => {
    onQuestionResponse(questionNo, target.value);
    setCharsLeft(MAX_TEXT_LENGTH - responseValue.length);
  };

  useEffect(() => {
    setCharsLeft(MAX_TEXT_LENGTH - responseValue.length);
  }, [responseValue.length]);

  return (
    <div className="d-flex">
      <div className="flex-fill">
        <label className="d-inline" htmlFor={`text_${questionNo}`}>
          {question.text[locale]}
        </label>
        {error ? (
          <p className="mb-1">
            <ErrorLabel question={question} />
          </p>
        ) : null}
        <textarea
          className="form-control"
          id={`text_${questionNo}`}
          value={responseValue}
          rows="4"
          maxLength={MAX_TEXT_LENGTH}
          onChange={handleChange}
        ></textarea>
        <p className="text-right font-weight-light">
          <small>
            <FormattedMessage id={"textQuestion.charactersLeft"} /> {charsLeft}
          </small>
        </p>
      </div>
    </div>
  );
};

export default TextQuestion;
