import React from "react";

import ErrorLabel from "./common/errorLabel";

//Componente domanda di tipo lista

const ListQuestion = ({ question, locale, onQuestionResponse }) => {
  const { questionNo, responseValue, options } = question;

  const handleChange = ({ currentTarget }) => {
    const { value } = currentTarget;
    onQuestionResponse(questionNo, value);
  };

  return (
    <div className="d-flex">
      <div className="flex-fill">
        {question.text[locale]}
        <ErrorLabel question={question} />
      </div>
      <div className="min-vw-12 text-right align-middle">
        <select
          className="form-control min-vw-12"
          onChange={handleChange}
          value={responseValue}
        >
          <option key="0" value=""></option>
          {options.map(opt => {
            const optionText = opt.text && opt.text[locale];
            return (
              <option key={opt.value} value={opt.value}>
                {optionText}
              </option>
            );
          })}
        </select>
      </div>
    </div>
  );
};

export default ListQuestion;
