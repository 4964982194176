//Librerie esterne
import React, { useState, useEffect, useCallback } from "react";
import { IntlProvider, FormattedMessage } from "react-intl";
import { Fade } from "react-reveal";
import _ from "lodash";
import { ToastContainer, toast } from "react-toastify";

//Pagine questionario
import LandingPage from "./pages/landingPage";
import FinalLandingPage from "./pages/finalLandingPage";
import QuestionPage from "./pages/questionPage";
import ErrorPage from "./pages/errorPage";
import SpinnerPage from "./pages/spinnerPage";

//Componenti vari
import ProgressBar from "./progressBar";

//Funzioni per chiamate a Web Services
import {
  getQuestionnaireInfo,
  markQuestionnaireViewed,
  submitQuestionnaire,
} from "../services/httpServices";

//Funzioni di utility per gestione questionario
import {
  completeQuestionnaire,
  validateQuestionnairePage,
} from "../utils/questionnaireUtils";

//Configurazione questionario
import questionnaireConfig from "../questionnaire.json";

//Testi in lingua
import messages_it from "../i18n/it.json";
import messages_en from "../i18n/en.json";
import messages_de from "../i18n/de.json";

const Questionnaire = ({ match }) => {
  /* currentPage è un parametro di stato fondamentale e indica la pagina del questionario correntemente
     visualizzata (vedere funzione renderPage). I numeri da 0 in poi indicano le pagine di domande, in
     base alla configurazione (questionnaire.json), mentre tutti i numeri negativi indicano pagine
     speciali:
     -1 -> Landing page iniziale;
     -2 -> Landing page finale;
     -3 -> Pagina di attesa (spinner);
     -4 -> Pagina di errore;
     La variabile viene inizializzata a -3, dimodochè venga immediatamente visualizzata la pagina di
     attesa, mentre viene chiamato il Web Service per la rilevazione delle informazioni sul questionario
     dal server.
  */
  const [currentPage, setCurrentPage] = useState(-3);
  //Altre variabili di stato
  const [currentLocale, setCurrentLocale] = useState("it"); //La locale di default è l'italiano
  const [questionnaireStatus, setQuestionnaireStatus] = useState("filled");
  const [surveyStatus, setSurveyStatus] = useState("closed");

  /* La variabile di stato questionnaire viene utilizzata in maniera duplice. Al caricamento del
   * componente, questa viene inizializzata con tutta la struttura del questionario, ripresa dal file
   * questionnaire.json. Lo stesso oggetto viene altresì utilizzato per memorizzare le risposte alle
   * domande, man mano che l'utente compila il questionario. */
  const [questionnaire, setQuestionnaire] = useState({});

  //Collection messaggi i18n
  const messages = {
    it: messages_it,
    de: messages_de,
    en: messages_en,
  };

  //ID questionario (chiave di rilevazione), passato mediante URL
  const qid = match.params.qid;

  //########## Funzioni di gestione chiamate Web Services ##########
  //Rileva dal server i dati relativi al questionario e lo marca come consultato
  const fetchQuestionnaireInfo = useCallback(async () => {
    try {
      //WS Call: Rilevazione informazioni questionario
      const questionnaireInfo = await getQuestionnaireInfo(qid);
      if (!questionnaireInfo || questionnaireInfo.error) throw Error("");

      //WS Call: Impostazione questionario consultato
      const wsResult = await markQuestionnaireViewed(qid);
      if (!wsResult) throw Error("");

      setQuestionnaireStatus(questionnaireInfo.status); //Imposta lo stato del questionario
      setSurveyStatus(questionnaireInfo.surveyStatus); //Imposta lo stato del sondaggio
      setCurrentLocale(questionnaireInfo.languageCode); //Imposta la lingua predefinita per il questionario
      //Carica la configurazione e imposta il questionario
      setQuestionnaire(completeQuestionnaire(questionnaireConfig));
      if (questionnaireInfo.status === "filled") {
        setCurrentPage(-2); //Imposta la landing page finale
      } else {
        setCurrentPage(-1); //Imposta la landing page
      }

      //setCurrentPage(7); //Debug: usare solo per testing pagine specifiche
    } catch (ex) {
      setCurrentPage(-4); //Imposta la pagina di errore
    }
  }, [qid]);

  const sendQuestionnaire = useCallback(async () => {
    try {
      //WS Call: Impostazione questionario consultato
      const wsResult = await submitQuestionnaire(qid, questionnaire);
      if (!wsResult) throw Error("");

      setQuestionnaireStatus("filled"); //Imposta lo stato del questionario
      setCurrentPage(-2); //Imposta la landing page finale
    } catch (ex) {
      setCurrentPage(-4); //Imposta la pagina di errore
    }
  }, [qid, questionnaire]);

  //########## Eventi lifecycle ##########

  //Montaggio componente
  useEffect(() => {
    fetchQuestionnaireInfo();
  }, [fetchQuestionnaireInfo]);

  /* Spiegone del perchè c'è fetchQuestionnaireInfo nella lista delle proprietà. Serve per evitare il messaggio di warning del 
     compilatore sulle dipendenze.
     In sostanza la chiamata a useEffect viene eseguita solo se cambia fetchQuestionnaireInfo, ma questo non avviene mai
     perchè tale funzione è definita mediante useCallBack con proprietà di controllo [qid] (che è sempre uguale) e ciò significa
     che non viene mai ricreata al rendering del componente. Si crea la prima volta e resta nella cache. Ergo questa useEffect
     viene eseguita una sola volta per tutta la vita del componente e il compilatore è felice. 
     L'alternativa sarebbe dichiarare la funzione fetchQuestionnaireInfo come variabile all'interno della funzione di useEffect,
     ma come soluzione fa un po' schifo.*/

  //######################################

  //########## Handling eventi ##########

  /* Richiamato ad ogni risposta inserita (basicamente l'evento onChange sui qualsiasi
   * campo domanda del questionario), provvede ad aggiornare l'oggetto di stato questionnaire,
   * aggiungendo il valore della risposta a tale domanda, passato dal chiamante. */
  const handleQuestionResponse = useCallback(
    (groupNo, questionNo, responseValue) => {
      const newQuestionnaire = _.cloneDeep(questionnaire);
      const page = newQuestionnaire.find((p) => p.groupNo === groupNo);

      //Rileva la pagina che corrisponde al gruppo indicato
      if (page) {
        //Rileva la domanda che corrisponde al numero indicato
        const question = page.questions.find(
          (q) => q.questionNo === questionNo
        );
        if (question) {
          //Imposta il valore della risposta
          question.responseValue = responseValue;
          setQuestionnaire(newQuestionnaire);
        }
      }
    },
    [questionnaire]
  );

  //Handling cambio pagina
  const handlePageChange = useCallback(
    (step, isLast) => {
      if (currentPage >= 0) {
        //Da usare per testing
        const newQuestionnaire = _.cloneDeep(questionnaire);
        const anyError = validateQuestionnairePage(
          newQuestionnaire[currentPage]
        ); //Esegue la validazione del questionario
        setQuestionnaire(newQuestionnaire); //Imposta lo stato
        if (anyError) {
          toast.error(<FormattedMessage id="error.checkValidation" />, {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "toast-error",
            bodyClassName: "toast-error-body",
            autoClose: 1500,
          });
          return;
        }
      }

      //Se la pagina che richiama l'evento è l'ultima, significa che il questionario va inviato
      if (isLast) {
        //Imposta la pagina di attesa immediatamente.
        //Ci penserà poi la callback a reimpostare la pagina corretta a seconda dell'esito dell'operazione
        setCurrentPage(-3);
        sendQuestionnaire(); //Richiama il metodo di gestione WS
      } else setCurrentPage(currentPage + step);
    },
    [currentPage, questionnaire]
  );

  const handleLocaleChange = useCallback((locale) => {
    setCurrentLocale(locale);
  }, []);

  //############################################################

  //########## Altre funzioni ##########

  //Rendering della pagina in base all'indicatore di stato currentPage
  const renderPage = () => {
    /* La progress bar dev'essere visibile solo nelle pagine di domande, oppure nella landing
     * page finale, per indicare il 100% */
    const showProgressBar = currentPage >= 0 || currentPage === -2;

    //Selezione pagina
    let page;
    switch (currentPage) {
      case -1: //Landing page
        page = (
          <LandingPage
            surveyStatus={surveyStatus}
            locale={currentLocale}
            onPageChange={handlePageChange}
            onLocaleChange={handleLocaleChange}
          />
        );
        break;

      case -2: //Landing page finale
        page = <FinalLandingPage locale={currentLocale} />;
        break;

      case -3: //Pagina di attesa
        page = <SpinnerPage />;
        break;

      case -4: //Pagina di attesa
        page = <ErrorPage messageId="error.webService" />;
        break;

      default:
        //Default: pagina domande
        const clonedQuestionnaire = _.cloneDeep(questionnaire); //Per sicurezza passa una copia del questionario
        page = (
          <QuestionPage
            questionnaire={clonedQuestionnaire}
            pageNo={currentPage}
            locale={currentLocale}
            onPageChange={handlePageChange}
            onQuestionResponse={handleQuestionResponse}
          />
        );
    }

    return (
      <>
        {showProgressBar ? (
          <ProgressBar
            progress={
              questionnaireStatus === "filled"
                ? questionnaire.length
                : currentPage
            }
            total={questionnaire.length}
          />
        ) : null}
        {page}
      </>
    );
  };

  return (
    <IntlProvider locale={currentLocale} messages={messages[currentLocale]}>
      <ToastContainer />
      <Fade spy={currentPage} duration={1200}>
        {renderPage()}
      </Fade>
    </IntlProvider>
  );
};

export default Questionnaire;
