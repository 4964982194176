import axios from "axios";
import { apiEndpoints } from "../config.json";

//Ritorna l'oggetto con le informazioni del questionario dal Server
export async function getQuestionnaireInfo(qid) {
  const { data } = await axios.get(
    `${apiEndpoints.getQuestionnaireInfo}/${qid}`
  );
  return data;
}

//Ritorna solo true o false
export async function markQuestionnaireViewed(qid) {
  const { data } = await axios.put(
    `${apiEndpoints.markQuestionnaireViewed}/${qid}`
  );
  if (!data || !data.result || data.result !== "OK") return false;

  return true;
}

/* Funzione di utility che converte l'oggetto questionario in un altro oggetto adatto
 * per essere utilizzato come richiesta per il Web Service. In modo particolare, la
 * funzione rimuove la suddivisione in pagine aggregando le domande per tipo e gruppo,
 * rinomina alcuni elementi e converte alcuni valori di risposta adattandoli alle specifiche
 * del WS. */
function createQuestionnaireRequest(questionnaire) {
  const result = {};

  let responseSection, value;
  for (let page of questionnaire) {
    //Cicla sulle pagine del questionario
    for (let question of page.questions) {
      //Cicla sulle domande di ogni pagina
      responseSection = `${question.type}Responses`;
      value = question.responseValue;
      //Eventuale correzione valore
      if (question.type === "number") value = parseInt(value);

      if (!(responseSection in result)) result[responseSection] = []; //Crea la sezione nell'oggetto richiesta, se non presente
      result[responseSection].push({
        //Aggiunge la risposta
        groupNo: page.groupNo,
        responseNo: question.questionNo,
        value
      });
    }
  }

  return result;
}

//Invia il questionario completato al server. Restituisce true se ha successo.
export async function submitQuestionnaire(qid, questionnaire) {
  const questionnaireRequest = createQuestionnaireRequest(questionnaire);

  console.log(questionnaireRequest);

  const { data } = await axios.put(
    `${apiEndpoints.submitQuestionnaire}/${qid}`,
    questionnaireRequest
  );
  if (!data || !data.result || data.result !== "OK") return false;

  return true;
}
