import React from "react";
import { FormattedMessage } from "react-intl";

import LocaleSelector from "../common/localeSelector";

const LandingPage = ({
  surveyStatus,
  locale,
  onPageChange,
  onLocaleChange
}) => {
  return (
    <React.Fragment>
      <h2>
        <FormattedMessage id="landingPage.dearCustomer" />
      </h2>
      <p className="lead">
        <FormattedMessage
          id={
            surveyStatus !== "open"
              ? "landingPage.closedSurvey"
              : "landingPage.openQuestionnaire"
          }
          values={{
            br: <br />,
            a: (...chunks) => (
              <a href="https://www.tecnicagasket.com">{chunks}</a>
            )
          }}
        />
      </p>
      {surveyStatus === "open" ? (
        <div className="d-flex align-items-end mt-3">
          <div>
            <button
              onClick={() => onPageChange(1, false)}
              className="btn btn-primary btn-lg mt-3 mr-5"
              autoFocus={true}
            >
              <FormattedMessage id="landingPage.startQuestionnaire" />
            </button>
          </div>
          <div>
            <LocaleSelector locale={locale} onLocaleChange={onLocaleChange} />
          </div>
        </div>
      ) : null}
    </React.Fragment>
  );
};

export default LandingPage;
